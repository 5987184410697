var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CBreadcrumb',{staticStyle:{"margin-top":"-28px"},attrs:{"items":_vm.links}}),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardBody',[_c('TheTableHeader',{attrs:{"header":'AUTHORIZED PERSON',"subHeader":'DAFTAR PEMILIK AKSES KE RUANGAN DI DATA CENTER LINTASARTA.',"buttonShow":"","buttonText":'Add New',"buttonUrl":'users/form/0'}})],1)],1),_c('CCard',[_c('CCardBody',[_c('CDataTable',{attrs:{"hover":"","striped":"","border":"","small":"","tableFilter":"","sorter":"","itemsPerPageSelect":"","items":_vm.users,"fields":_vm.fields,"items-per-page":5,"pagination":"","loading":_vm.isLoading},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"align":"center"}},[_vm._v(_vm._s(_vm.users.map(function(x) {return x.id; }).indexOf(item.id)+1))])]}},{key:"email",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.email)),_c('br'),_c('small',[_vm._v("mobile: "+_vm._s(item.mobile_phone))])])]}},{key:"role",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.role)),_c('br'),_c('small',[_vm._v("Access Card: "),_c('strong',[_vm._v(_vm._s(item.role === 'Guest' ? 'Not Available' : item.access_card_number))])])])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(item.status)}},[_vm._v(_vm._s(item.status))])],1)]}},{key:"signature",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('p',[_vm._v(_vm._s(item.signature = null ? 'Expired' : 'Valid'))])])]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"align":"center"}},[_c('p',[_vm._v(_vm._s(_vm._f("formatDate")(item.created_at))+" "+_vm._s(_vm._f("formatTime")(item.created_at)))])])]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"align":"center"}},[(_vm.role <= 2)?_c('CButton',{attrs:{"color":"success","variant":"outline","square":"","size":"sm","disabled":item.status === 'Active'},on:{"click":function($event){return _vm.approveUser(item.id)}}},[_vm._v("Approve")]):_vm._e(),_vm._v("   "),_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm","disabled":_vm.role === 4},on:{"click":function($event){return _vm.editUser(item.id)}}},[_vm._v("Edit")]),_vm._v("   "),(_vm.role < 3)?_c('CButton',{attrs:{"color":"danger","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.removeUser(item.id)}}},[_vm._v("Delete")]):_vm._e()],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }