<template>
	<div>
		<CBreadcrumb :items="links" style="margin-top: -28px;"/>
		<CRow>
			<CCol sm="12">

				<CCard>
    			<CCardBody>
						<TheTableHeader
							:header="'AUTHORIZED PERSON'"
							:subHeader="'DAFTAR PEMILIK AKSES KE RUANGAN DI DATA CENTER LINTASARTA.'"
							buttonShow
							:buttonText="'Add New'"
							:buttonUrl="'users/form/0'"
						/>
					</CCardBody>
				</CCard>

				<CCard>
					<CCardBody>
						<CDataTable
							hover
							striped
							border
							small
							tableFilter
							sorter
							itemsPerPageSelect
							:items="users"
							:fields="fields"
							:items-per-page="5"
							pagination
							:loading="isLoading"
						>
							<template #id="{item}">
								<td align="center">{{users.map(function(x) {return x.id; }).indexOf(item.id)+1}}</td>
							</template>
							<template #email="{item}">
								<td>{{item.email}}<br><small>mobile: {{item.mobile_phone}}</small></td>
							</template>
							<template #role="{item}">
								<td>{{item.role}}<br><small>Access Card: <strong>{{item.role === 'Guest' ? 'Not Available' : item.access_card_number}}</strong></small></td>
							</template>
							<template #status="{item}">
								<td>
									<CBadge :color="getBadge(item.status)">{{ item.status }}</CBadge>
								</td>
							</template>
							<template #signature="{item}">
								<td>
									<p>{{item.signature = null ? 'Expired' : 'Valid'}}</p>
								</td>
							</template>
							<template #created_at="{item}">
								<td align="center">
									<p>{{item.created_at | formatDate}}  {{item.created_at | formatTime}}</p>
								</td>
							</template>
							<template #action="{item}">
            		<td align="center">
									<CButton color="success" variant="outline" square size="sm" :disabled="item.status === 'Active'" v-if="role <= 2" @click="approveUser(item.id)">Approve</CButton> &nbsp; 
              		<CButton color="primary" variant="outline" square size="sm" :disabled="role === 4" @click="editUser(item.id)">Edit</CButton> &nbsp; 
									<CButton color="danger" variant="outline" square size="sm" v-if="role < 3" @click="removeUser(item.id)">Delete</CButton>
            		</td>
          		</template>
						</CDataTable>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>
	</div>	
</template>

<script>
import http from '@/utils/http-common';
import TheTableHeader from '@/containers/TheTableHeader.vue'

export default {
	name: 'TableMasterUser',
	components: { TheTableHeader },
	data () {
		return {
			role: +localStorage.getItem('role'),
			// company_id: this.$store.state.user.user.company_id,
			company_id: +localStorage.getItem('company_id'),
			users: [],
			fields: [{ key: 'id', label: 'No' }, 'name', { key: 'email', label: 'Contact' }, { key: 'role', label: 'Role' }, { key: 'company_name', label: 'Company' }, 'status', 'action'],
			currentPage: 1,
			perPage: 5,
			totalRows: 1,
			isLoading: true,
			links: [
        {
          text: 'Home',
          href: '#/'
        }, 
        {
          text: 'Daftar Pengunjung',
        }
      ],
		}
	},
	paginationProps: {
		align: 'right',
		doubleArrows: false,
		previousButtonHtml: 'prev',
		nextButtonHtml: 'next'
	},
	computed: {
		user () {
    	return this.$store.state.user;
  	},
  },
	methods: {
		toast(message, type) {
      this.$toast.open({
        message: message,
        type: type, // success, info, warning, error, default
        position: "top", // top, bottom, top-right, bottom-right,top-left, bottom-left
        duration: 3000,
        dismissible: true
      })
    },
		goCreate(){
      this.$router.push({ path: 'users/form/0' });
    },
		getBadge (status) {
			return status === 'Active' ? 'success' : 'danger'
		},
		getUsers() {
			let self = this;

			if (self.role <= 5) {
				return http.get('/users')
				.then(function (response) {
					let list = response.data.users;

					if (self.role === 3 || self.role === 5) {
						list = list.filter(function(item) {
    					return item.role === 'Guest';  
						});
					}

					if (self.role === 4) {
						list = list.filter(function(item) {
    					return item.company_id === self.company_id;  
						});
					}

					if (self.role === 2) {
						list = list.filter(function(item) {
    					return item.status === "InActive";  
						});
					}
					
					self.users = list;
					self.isLoading = false;
				}).catch(function (error) {
					console.log(error);
				});
			} else {
				return http.get('/users/company/' + self.company_id)
				.then(function (response) {
					const list = response.data.user;
					self.users = list;
					self.isLoading = false;
				}).catch(function (error) {
					console.log(error);
					self.isLoading = false;
				});
			}
			
		},
		approveUser(id) {
      this.$router.push({ path: 'users/approve/' + id });
    },
		editUser(id) {
      this.$router.push({ path: 'users/form/' + id });
    },
		removeUser(id) {
			let self = this;
			if (confirm('Yakin Ingin Menghapus ?')) {
				if (id > 1) {
					return http.delete('/users/' + id)
					.then(function (response) {
						self.getUsers();
						self.toast('Berhasil Menghapus Pengunjung Dari Daftar.', 'info');
						console.log(response);
					}).catch(function (error) {
						console.log(error);
					});
				} else {
					self.toast('Tidak Boleh Menghapus Admin!', 'danger')
				}
    		
  		}
		}
	},
	mounted: function(){
		this.getUsers();
	}
}

</script>
